<template>
  <section>
    <h1 class="bold-header-text app-header mb-3"><span>ACHC Today </span></h1>
    <b-row class="mb-2">
      <b-col md="12">
        <p>
          Read our monthly newsletter to keep up-to-date on ACHC and industry news. Every month we
          curate the most important news to help you stay ahead of your competition.
        </p>
        <achc-collapsible-card
          v-for="({ year, resource }, index) in rsclist"
          :key="`resource_${index}`"
          :card-visible="index == 0 ? true : false"
        >
          <template slot="header">
            <h2 class="resource-program-header">{{ year }}</h2>
          </template>
          <template slot="body">
            <div
              class=""
              v-for="{ dateCreated = '', url = '', title = '', resourceID = null } in resource"
              :key="resourceID"
            >
              <h3 class="resource-category-header">{{ formattedDate(dateCreated) }}</h3>
              <a class="resource-link" target="_blank" :href="url" :title="title">
                {{ title }}
              </a>
            </div>
          </template>
        </achc-collapsible-card>
        <div v-if="emptyStateVisible" class="resources-empty-state">No resources available</div>
      </b-col>
    </b-row>
  </section>
</template>
<style lang="scss" scoped></style>
<script>
import CollapsibleCard from "../../../components/Common/CollapsibleCard.vue";
import ResourceMixin from "../../../mixins/ResourceMixin.vue";

export default {
  components: {
    "achc-collapsible-card": CollapsibleCard,
  },
  data() {
    return {
      rsclist: [],
    };
  },
  async created() {
    await this.getResources("9");
    if (this.resources && this.resources.length) {
      this.rsclist = this.resources[0].categories[0].categoryResources;
    }
  },
  mixins: [ResourceMixin],
  computed: {
    emptyStateVisible() {
      return !this.resources.length;
    },
  },
  methods: {
    formattedDate(date) {
      const date_value = new Date(date);
      return (
        date_value.toLocaleString("default", { month: "long" }) + " " + date_value.getFullYear()
      );
    },
  },
};
</script>
